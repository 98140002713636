import styled from '@emotion/styled'

const Breadcrumb = styled.ul`
list-style: none;
margin: 0 0 20px;
padding: 0;
font-size: 12px;
li {
  display: inline-block;
  padding-right: 30px;
  position: relative;
  &:after {
    content: ">";
    font-family: monospace;
    display: inline-block;
    line-height: 1.5;
    font-weight: 400;
    color: rgb(84,84,105);
    position: absolute;
    right: 14px;
  }
  &:last-child:after {
    display: none;
  }
  a {
    color: #888;
    text-decoration: none;
  }
}
`

export default Breadcrumb
