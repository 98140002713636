import styled from '@emotion/styled'

const Title = styled.h2`
  font-size: 42px;
  font-weight: 300;
  color: #247dbd;
  text-align: left;
  position: relative;
  margin: 0;  
  z-index: 1;
  &:before {
    content: "";
    height: 20px;
    background: #f0f8ff;
    position: absolute;
    bottom: 0;
    left: -50px;
    width: 100px;
    z-index: -2;
  }
  @media (max-width: ${props => props.theme.breakpoints.l}) {
    &:before {
      width: 1.5rem;
      left: -40px;
    }
    font-size: 58px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    &:before {
      width: 1.5rem;
      left: -35px;
    }
    font-size: 32px;
  }
`

export default Title
