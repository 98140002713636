import styled from '@emotion/styled'

const Description = styled.p`
    color: #828282;
    display: block;
    line-height: 1.5;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 16px;
    }
`

export default Description
