import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './Facebook'
import Twitter from './Twitter'
import i18n from '../../../config/i18n'

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

const SEO = ({ title, desc, banner, pathname, JobPosting, node, locale }) => {
  const { site } = useStaticQuery(query)
  const { defaultTitle, defaultDescription, siteLanguage, headline, categories } = i18n[locale]

  const {
    buildTime,
    siteMetadata: { siteUrl, defaultBanner, ogLanguage, author, twitter, facebook },
  } = site

  const localizedPath = i18n[locale].default ? '' : `/${i18n[locale].path}`
  const homeURL = `${siteUrl}${localizedPath}`

  const seo = {
    title: title || defaultTitle,
    description: desc || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ''}`,
  }

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: homeURL,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: homeURL,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2019',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultBanner}`,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ]

  let schemaJobPosting = null

  if (JobPosting) {
    schemaJobPosting = {
      '@context': 'http://schema.org',
      '@type': 'JobPosting',
      'headline': node.data.title.text,
      'title': node.data.title.text,
      'description': node.data.job_description.html,
      identifier: {
        '@type': 'PropertyValue',
        'name': "Dceed A.Ş.",
        'value': node.prismicId,
      },
      datePosted: node.first_publication_date,
      applicantLocationRequirements: {
        "@type": "Country",
        "name": "Turkey"
      },
      employmentType: "FULL_TIME",
      hiringOrganization : {
        "@type" : "Organization",
        "name" : "Dceed Bilisim Teknolojileri A.S.",
        "sameAs" : "https://dceed.com",
        "logo" : "https://dceed.com/images/logo.png"
      },
      jobLocation: {
      "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Üsküdar",
          "addressLocality": ", Istanbul",
          "addressRegion": "Istanbul",
          "postalCode": "34680",
          "addressCountry": "TR"
        }
      },
    }
    // Push current blogpost into breadcrumb list
    itemListElement.push(
      {
        '@type': 'ListItem',
        item: {
          '@id': `${homeURL}/jobs`,
          name: 'jobs',
        },
        position: 2,
      },
      {
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    })
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {/* Insert schema.org data conditionally (webpage/JobPosting) + everytime (breadcrumbs) */}
        {!JobPosting && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>}
        {JobPosting && <script type="application/ld+json">{JSON.stringify(schemaJobPosting)}</script>}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        type={JobPosting ? 'JobPosting' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter title={seo.title} image={seo.image} desc={seo.description} username={twitter} />
    </>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  JobPosting: PropTypes.bool,
  node: PropTypes.object,
  locale: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  desc: null,
  banner: null,
  pathname: null,
  JobPosting: false,
  node: null,
  locale: 'de-de',
}

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultBanner: banner
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`
