import styled from '@emotion/styled'

const Subtitle = styled.p`
  font-size: 22px;
  font-weight: 200;
  line-height: 1.5;
  color: #000;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    &:before {
      width: 1.5rem;
      left: -35px;
    }
  }
`

export default Subtitle
