import styled from '@emotion/styled'
import LocalizedLink from './LocalizedLink'
import i18n from '../../config/i18n'
import { Link } from 'gatsby'

const LocaleSwitcher = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
  }
`
const Navbar = styled.div`
  background-color: #f5f5f5;
  border-bottom: 1px solid #ebebeb;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 10px;
  }
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    align-items: center;
    .logo {
      img {
        margin: 20px 0;
        height: 36px;
        width: auto;
        @media (max-width: ${props => props.theme.breakpoints.xs}) {
          height: 26px;
        }
      }
    }
    .navigation {
      margin-left: auto;
      ul.language {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: right;
        li {
          margin: 0;
          padding: 0;
          display: inline-block;
          a {
            padding: 3px 6px;
            font-size: 10px;
            text-decoration: none;
            color: #828282;
          }
        }
      }
      ul.main {
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: right;
        >li {
          margin: 0;
          padding: 0;
          display: inline-block;
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            &.hs {
              display: none;
            }
          }
          >a {
            margin: 0px 10px;
            padding: 10px;
            font-size: 14px;
            text-decoration: none;
            color: #828282;
            @media (max-width: ${props => props.theme.breakpoints.s}) {
              margin: 0px 5px;
              padding: 5px;
            }
            @media (max-width: ${props => props.theme.breakpoints.xs}) {
              font-size: 12px;
              padding: 5px;
            }
          }
          &.special {
            a {
              border: 2px solid #247dbd;
            }
          }
          &.locale {
            a {
              background: #3992d1;
              color: white!important;
            }
          }
        }
      }
      .dropdown {
        position: relative;
        display: inline-block;
        button.current {
          font-size: 12px;
          background: #3992d1;
          color: white!important;
          padding: 12px 16px;
          -webkit-text-decoration: none;
          text-decoration: none;
          text-transform: uppercase;
          display: block;
          border: 0;
          margin: 0;
          outline: 0;
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            margin: 0px 0 0 15px;
            padding: 5px;
            border: 2px solid #247dbd;
          }
          @media (max-width: ${props => props.theme.breakpoints.xs}) {
            font-size: 12px;
            margin: 0 0 0 5px;
            padding: 5px;
          }
        }
        .list {
          display: none;
          position: absolute;
          background-color: #f1f1f1;
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
          z-index: 1;
          @media (max-width: ${props => props.theme.breakpoints.xs}) {
            right: 0;
          }
          a {
            text-decoration: none;
            display: block;
            font-size: 12px;
            color: black!important;
            padding: 12px 16px;
          }
        }
        .locale {
          margin: 0;
        }
        &:hover .list{
          display: block;
        }
      }
    }
  }
`

const Navigation = ({locale }) => {
    const { siteLanguage, section} = i18n[locale]
    return (
        <Navbar>
        <div className="container">
          <div className="logo">
            <LocalizedLink to="/">
              <img src="/images/logo.svg" alt="Dceed Bilişim Teknolojileri A.Ş." title="Dceed Bilişim Teknolojileri A.Ş." loading="lazy" />
            </LocalizedLink >
          </div>
          <div className="navigation">
            
            <ul className="main">
              {/* {nav[0].node.data.body.map((i, index) => (
              <li className={`item ${i.primary.class}`} key={index} data-name={`nav-entry-${index}`}>
                <Link to={i.primary.link.url}>{i.primary.label.text}</Link>
              </li>
              ))} */}
              <li className="item">
                <LocalizedLink to="/#services">{section.services.title}</LocalizedLink >
              </li>
              <li className="item hs">
                <LocalizedLink to="/#about">{section.about.title}</LocalizedLink >
              </li>
              <li className="dropdown">
                <button className="current">{siteLanguage}</button>
                <LocaleSwitcher className="list" data-name="locale-switcher">
                  <li className="locale"><Link hrefLang="en-gb" to="/">EN</Link>{' '}</li>
                  <li className="locale"><Link hrefLang="de-de" to="/de">DE</Link>{' '}</li>
                  <li className="locale"><Link hrefLang="tr-tr" to="/tr">TR</Link>{' '}</li>
                </LocaleSwitcher>
              </li>
            </ul>
          </div>
        </div>
      </Navbar>
    )
}

export default Navigation
