import styled from '@emotion/styled'

const Section = styled.div`
  padding: 2rem 0 ;
  max-width:
  order: 1;
  flex:1;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 1.5rem;
    order: 2;
  }
`

export default Section