import styled from '@emotion/styled'
import {Title, Subtitle, Description} from '../components'

const Content = styled.div`
`
function SectionHeader(props) {
    return (
        <Content>
            <Title>{props.title}</Title>
            <Subtitle>{props.subtitle}</Subtitle>
            <Description><Content dangerouslySetInnerHTML={{ __html: props.description }}/> </Description>
        </Content>
    )
}

export default SectionHeader
