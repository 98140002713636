import React from 'react'
import styled from '@emotion/styled'

const Content = styled.div`
  padding: 3rem 0;
  background: ${props => (props.background)};
  position: relative;
  &.jobs {
    &:before {
      content: "";
      position: absolute;
      background: rgb(240, 248, 255);
      width: 30%;
      height: 40%;
      top: 40%;
      left: 0;
      z-index: -2;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        height: 10%;
      }
    }
  }
  
`

const Wrapper = ({ type, scrollId, children }) => (
  <Content className={type}  id={scrollId}>
    {children}
  </Content>
)


export default Wrapper
