import styled from '@emotion/styled'

const Container = styled.div`
    width: 100%;
    max-width: 1200px;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: ${props => props.theme.breakpoints.l}) {
        max-width: 1200px;
    }
    @media (max-width: ${props => props.theme.breakpoints.m}) {
        max-width: 960px;
    }
    @media (max-width: ${props => props.theme.breakpoints.s}) {
        max-width: 720px;
    }
    @media (max-width: ${props => props.theme.breakpoints.xs}) {
        max-width: 540px;
    }
`

export default Container
