import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import LocalizedLink from '../LocalizedLink'
import i18n from '../../../config/i18n'
import { kebabCase } from 'lodash'
const Item = styled.li`
  margin:0;
  .item {
    display: flex;
    padding: 12px 5px;
    align-items: center;
    text-decoration: none;
    color: black;
    margin: 0;
    border-bottom: 1px solid #ececec;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      flex-wrap: wrap;
    }
    .position {
      flex: 1 0 55%;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        flex: 1 1 100%;
        margin-bottom: 5px;
      }
      h3 {
        font-weight: 500;
        font-size: 14px;
      }
    }
    .location {
      flex: 1 0 15%;
      font-size: 14px;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 10px;
        margin-right: 10px;
        flex: 0 0 15%;
      }
    }
    .contract {
      flex: 1 0 15%;
      font-size: 14px;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        font-size: 10px;
      }
    }
    .button {
      flex: 1 0 15%;
      text-align: right;
      span {
        padding: 4px 12px;
        font-size: 12px;
        border: 2px solid #1e84cc;
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        display: none;
      }
    }
    &:hover {
      background: #f0f8ff;
      .button span{
        background: #1e84cc;
        color: white;
      }
    }
  }

`

const ListItem = ({ index, node, locale }) => {
  const { section} = i18n[locale]
  return (
  <Item key={index} >
    <LocalizedLink className="item" to={`/${kebabCase(node.uid)}`} aria-label="Back to Home">
      <div className="position">
        <h3>{node.data.title.text}</h3>
      </div>
      <div className="location">{node.data.location}</div>
      <div className="contract">{node.data.contract}</div>
      <div className="button"><span>{section.jobs.details.button}</span></div>
    </LocalizedLink>
  </Item>
  )
}

export default ListItem

ListItem.propTypes = {
  node: PropTypes.object.isRequired,
}
