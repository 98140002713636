import React from 'react'
import i18n from '../../config/i18n'
import styled from '@emotion/styled'
import {Container, Row, Col} from './'
import CookieConsent from "react-cookie-consent";
import LocalizedLink from './LocalizedLink'
import ReactTooltip from 'react-tooltip';

const StyledFooter = styled.footer`
  background: #242424;
  padding: 40px 0;
  margin-top: 100px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin-top: 50px;
  }
  .copyright {
    text-align: center;
    color: #616161;
    .logo {
      img {
        max-height: 42px;
        margin-bottom: 30px;
        width: auto;
      }
    }
    .disclaimer {
      a {
        color: #616161;
        text-decoration: none;
        border-bottom: 2px solid transparent;
        &:hover {
          border-bottom: 2px solid #616161;
        }
      }
      .terms {
        margin: 5px 0;
        padding: 0;
        font-size: 13px
      }
      .rights {
        margin: 5px 0;
        padding: 0;
        font-size: 13px
      }
    }
    .attribution {
      color: white;
      a {
        color: white;
        border-bottom: 1px solid #fff;
      }
    }
  }
  .footer {
    color: #eee;
    padding: 20px 0 50px ;
    font-size: 13px;
    .title {
      font-size: 14px;
      color: white;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .email a {
      color: white;
      text-decoration: none;
      font-size: 20px;
      font-weight: 600;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        display: inline-block;
        padding-right: 39px;
        position: relative;
        &:after {
          content: "/";
          display: inline-block;
          font-size: 16px;
          line-height: 1.5;
          font-weight: 400;
          color: rgb(84, 84, 105);
          position: absolute;
          right: 16px;
        }
        &:last-child:after {
          content: "";
        }
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          display: block;
          &:after {
            display: none;
          }
        }
      }
    }
  }
`

const Footer = ({locale }) => {
  const { section, forms} = i18n[locale]
  return (
    <StyledFooter>
      <CookieConsent
          location="none"
          buttonText={forms.cookie.accept}
          declineButtonText={forms.cookie.decline}
          cookieName={"gdpr-google-analytics", "gdpr-google-tagmanager"}
          disableStyles={true}
          style={{ background: "black",color: "white",maxWidth: "300px",fontFamily: "monospace, serif",fontSize: "14px",padding: "20px",position: "fixed",bottom: "20px",right: "20px",zIndex:'22'}}
          buttonStyle={{color: "white",background: "#1e84cc",fontSize: "13px",border: "0",padding: "6px 14px",margin: "20px 0 0",}}>
          <p style={{ fontSize: "16px", marginBottom:"10px", fontWeight:"600"}}>{forms.cookie.title}</p>
          {forms.cookie.text}
      </CookieConsent>
        <Container>
          <Row className="footer" flow="wrap">
            <Col className="col-6">
              <p className="title">{section.footer.contact}</p>
              <p className="email"><a href="mailto:contact@dceed.com">contact@dceed.com</a></p>
              <p className="email" ><a href="tel:+902127069806">Tel: 0212 706 98 06</a></p>
              <p>Address:<br />Ünalan Mh. Libadiye Cd. No:82 <br/>Emaar Square Sitesi E blok Kat: 29 <br/>Üsküdar, İstanbul</p>
            </Col>
            <Col className="col-6">
              <p className="title">{section.footer.services}</p>
              <ul>
                <li>{section.footer.software}</li>
                <li>{section.footer.custom}</li>
                <li>{section.footer.business}</li>
                <li>{section.footer.consulting}</li>
                <li>{section.footer.outsourcing}</li>
              </ul>
            </Col>
          </Row>
          <Row className="copyright">
            <Col>
              <div className="logo">
                <LocalizedLink to="/"><img src="/images/logo.svg" alt="Dceed Bilişim Teknolojileri A.Ş." title="Dceed Bilişim Teknolojileri A.Ş." loading="lazy" /></LocalizedLink>
              </div>
              <div className="disclaimer">
                <p className="terms"><LocalizedLink to="/privacy">Privacy policy</LocalizedLink> | <LocalizedLink to="/imprint">Imprint</LocalizedLink> | <a data-tip data-for='global'>Disclaimer</a></p>
                <p className="rights" data-tip="hello world">© 2020 Dceed Bilişim Teknolojileri Anonim Şirketi. All rights reserved. </p>
                <ReactTooltip id='global' aria-haspopup='true' >
                  <div className="attribution" style={{maxWidth:"200px",textAlign:"left",fontSize:"10px"}}>
                    We are using:<br/>
                    Icons by various Artists from <a href="https://www.flaticon.com/" title="Flaticon" rel="nofollow">www.flaticon.com</a> and Photos from various Photographers from <a href="https://www.pexels.com/" rel="nofollow">Pexels</a>
                  </div>
                </ReactTooltip>
              </div>
            </Col>
          </Row>
      </Container>
  </StyledFooter>
  )
}



export default Footer
