import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Content = styled.div`
  max-width: ${props => props.theme.maxWidthText};
`

const IconBlock = ({ input }) => (
  <Content>
    <p>bbbbbbbbbbbbbbbb</p>
    <div>{input.primary.service_description}</div>
  </Content>
)

export default IconBlock

IconBlock.propTypes = {
  input: PropTypes.object.isRequired,
}
