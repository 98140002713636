import styled from '@emotion/styled'

const Sidebar = styled.div`
  border-left: 1px solid #f2f2f2;
  padding-left: 10px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
  }
  .group {
    .subheading {
      display:block;
      font-weight:600;
    }
  }
`

export default Sidebar
