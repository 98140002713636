import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import ListItem from './ListItem'
import i18n from '../../../config/i18n'

const List = styled.ul`
  list-style-type: none;
  margin-left: 0;
`
const JobWrapper = styled.div `
  h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.5;
    color: #000;
  }
`
const Thead = styled.li`
  display: flex;
  font-weight: bold;
  font-weight: 500;
  font-size: 12px;
  padding: 5px;
  background: aliceblue;
  .position {
    flex: 1 1 55%;
  }
  .location {
    flex: 1 1 15%;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      display: none;
    }
  }
  .contract {
    flex: 1 1 15%;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      display: none;
    }
  }
  .button {
    flex: 1 0 15%;
  }
`
const Listing = ({ posts, locale, type }) => {
  const { template, page} = i18n[locale]
  return (
    <JobWrapper>
    <h2>{page.jobs.open_positions} </h2>
    <List className={type}>
      <Thead className='thead'>
        <div className='position'>{template.job.position}</div>
        <div className='location'>{template.job.location}</div>
        <div className='contract'>{template.job.contract}</div>
        <div className='button'></div>
      </Thead>
      {posts.map((i, index) => (
        <ListItem key={index} node={i.node} locale={locale}/>
      ))}
    </List>
    </JobWrapper>
  )
}
export default Listing

Listing.propTypes = {
  posts: PropTypes.array.isRequired,
}
