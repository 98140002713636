import styled from '@emotion/styled'

const Row = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: ${props => (props.flow === "wrap" ? "wrap" : "nowrap")};
    flex-direction: ${props => (props.direction === "column" ? "column" : "row")};
    align-items: ${props => (props.align)};
    margin-right: -20px;
    margin-left: -20px;
`

export default Row
